html {
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto !important;
  }

  @media screen and (min-width: 1600px) {
    font-size: 125%;
  }

  @media screen and (min-width: 1920px) {
    font-size: 150%;
  }

  @media screen and (min-width: 2500px) {
    font-size: 175%;
  }

  @media screen and (max-width: 320px) {
    font-size: 95%;
  }

  &:focus-within {
    @extend html;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  scroll-behavior: inherit;
  -webkit-tap-highlight-color: transparent;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  &::before,
  &::after {
    @extend *;
  }
}

body {
  min-height: 100vh;
  display: block;
  background: #030303;
  position: relative;
  color: white;
}

h1,
h2,
h3 {
  line-height: 1.1;
  margin-block: 0;
}

p {
  margin-block: 0;
  color: inherit;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  cursor: pointer;
  object-fit: cover;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: unset;
  font: inherit;
  text-decoration: none;
  text-decoration-skip-ink: none;

  &:visited,
  &:active {
    @extend a;
  }

  &:not([class]) {
    text-decoration-skip-ink: auto;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type="search"] {
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  &[type="radio"],
  &[type="checkbox"] {
    cursor: pointer;
  }
}

textarea {
  resize: none;
}

button {
  border: 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    color: hsl(0, 0%, 0%, 0.95);
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 7px;
}
