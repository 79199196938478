@use "./function" as *;

.confetti {
  position: fixed;
  z-index: 10;
  width: 25%;
  margin-left: rem(60);
  margin-top: rem(140);
  height: max-content;
}
