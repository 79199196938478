@use "function" as *;



.modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    width: 100%;
    min-height: 100dvh;
    padding: 1.5rem;
    display: grid;
    place-content: center;
    background: hsl(221 8% 58% / 0.6);
    backdrop-filter: blur(2px);

    .modal-content-body {
        width: rem(460);
        background-color: white;
        border-radius: 10px;
        position: relative;
        padding: 1.5rem;
        color: black;

        .modal-close-btn {
            position: absolute;
            top: rem(12.5);
            right: rem(20);
            background: transparent;

            svg {
                width: rem(12.5);
            }
        }

        .modal-content {
            display: grid;
            place-items: center;
            gap: 0.5rem;
            margin-top: rem(24);
        }
    }
}