@use "function" as *;

// Common Classes ----------

.modal-title {
  font-size: rem(20);
  font-weight: 600;
}

.modal-msg {
  font-size: rem(15);
}

.action-btns {
  background: transparent;
  font-size: rem(15);
  font-weight: 500;
  border: 1px solid black;
  padding: 0.5em 1.5em;
  border-radius: 4px;
}

.fill-btn {
  background: black;
  color: white;
}

// -------------------------------------

// Choose Recipient Component ----------

.choose-recipient-title {
  @extend .modal-title;
}

.choose-recipient-msg {
  @extend .modal-msg;
}

.choose-recipient-actions {
  margin-top: rem(32);
  width: 100%;
  display: flex;
  gap: 1rem;

  .action-btn {
    @extend .action-btns;
    flex: 1;

    &:last-of-type {
      @extend .fill-btn;
    }
  }
}

// -------------------------------------

// Airdrop Details ----------

.airdrop-title-container {
  display: grid;
  gap: 8px;

  .airdrop-icon {
    display: grid;
    place-items: center;
    background: transparent;
    border-radius: 50%;
    overflow: hidden;

    img {
      aspect-ratio: 1;
      width: rem(50);
      object-fit: cover;
    }
  }

  .airdrop-title {
    @extend .modal-title;
  }
}

.airdrop-msg {
  margin-top: rem(30);
  width: 100%;
  @extend .modal-msg;
}

.airdrop-details-form {
  width: 100%;
  display: grid;
  gap: rem(32);

  .details-form-control-group {
    display: grid;
    border: 1px solid hsl(210, 20%, 95%);
    border-radius: 5px;
    overflow: hidden;

    .control-label {
      font-size: rem(12);
      padding: 0.725em 0.6em;
      background: hsl(210, 20%, 96%);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-weight: 600;
      color: hsl(0, 0%, 58%);
    }

    .control-input {
      padding: 0.725em 0.6em;
      border: 0;
      font-weight: 500;
      border-top: 1px solid hsl(0, 0%, 50%);
      border-bottom: 1px solid hsl(0, 0%, 50%);

      &:focus {
        outline: none;
      }
    }
  }

  .form-submit-btn {
    @extend .action-btns;
    @extend .fill-btn;
  }
}

// -------------------------------------

// Switch Network Component ----------

.switch-network-container {
  width: max-content;
  display: grid;

  .switch-network-msg {
    font-size: rem(15.5);
    margin-bottom: rem(20);
    font-weight: 500;
  }

  .network-switch-btn {
    @extend .action-btns;
    @extend .fill-btn;
  }
}

// -------------------------------------
