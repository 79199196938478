@use "./function" as *;
.header {
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  z-index: 999;
  padding-block: 15px;
  padding-inline: 10rem;
  background-color: #010101;
  border-bottom: 1px solid hsl(0, 0%, 50%, 0.15);
  @media screen and (max-width: em(750)) {
    // position: relative;
  }
  @media screen and (min-width: 1600px) {
    padding-inline: 13rem;
  }
  .header-right {
    display: flex;
    width: 100%;
    gap: rem(50);
    .sec-center {
      .for-dropdown {
        width: max-content;
        min-width: rem(230);
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding-inline: 12px;
        border-radius: 8px;
        .chain-connected {
          display: flex;
          gap: 8px;
          img {
            width: rem(20);
            object-fit: contain;
            aspect-ratio: 1;
          }
        }
        .arrowdown {
          margin-left: rem(10);
          border: solid black;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
      .section-dropdown {
        top: rem(35);
        border-radius: rem(10);
        &.hide-drop {
          display: none;
        }
        .select-network {
          color: black;
          font-weight: 700;
          font-size: rem(12);
          text-transform: uppercase;
          padding-block-start: rem(10);
          padding-block-end: rem(4);
          align-items: flex-end;
          padding-inline: 15px;
          display: flex;
        }
        .main-test {
          display: grid;
          position: relative;
          grid-template-columns: 1fr 1fr;
          color: black;
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 50%;
            margin-inline: auto;
            bottom: 0;
            left: 0;
            background-color: black;
            transition: left 200ms linear;
          }
          &:has(.mainnet.active) {
            &::after {
              left: 0;
              transition: left 200ms linear;
            }
          }
          &:has(.testnet.active) {
            &::after {
              left: 50%;
              transition: left 200ms linear;
            }
          }
          button {
            padding-block: em(8);
            display: inline-grid;
            place-content: center;
            position: relative;
            color: hsl(0, 1%, 50%);
            font-size: rem(14);

            &.active::after {
              width: 100%;
              left: 0;
              color: black;
            }
            &.active {
              color: black;
            }
          }
        }

        .option-container {
          display: grid;
          padding-top: rem(10);
          padding-bottom: rem(6);
          .drop-btn {
            cursor: pointer;
            display: flex;
            min-width: rem(220);
            white-space: nowrap;
            padding-inline: rem(17);
            .network-logo {
              align-items: center;
              display: flex;
              gap: rem(8);
              img {
                width: rem(20);
                object-fit: fill;
                aspect-ratio: 1;
              }
            }
            &:hover {
              background-color: hsl(0, 0%, 80%);
              color: #010101;
            }
          }
          .dot {
            width: rem(10);
            height: rem(10);
            background-color: green;
            border-radius: 100%;
            margin-left: rem(5);
          }
        }
      }
      .section-dropdown:after {
        display: none;
      }
    }
    @media screen and (max-width: em(750)) {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      width: 100%;
      position: absolute;
      top: 31px;
      background-color: black;
    }
  }
  .container {
    display: flex;
    margin-inline: auto;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: em(1100)) {
      gap: 2rem;
    }
    @media screen and (max-width: em(750)) {
      justify-content: space-between;
      position: relative;
    }
    .toggle {
      display: none;
      @media screen and (max-width: em(750)) {
        width: rem(100);
        display: flex;
        justify-content: flex-end;
        padding-right: 5rem;
      }
      @media screen and (max-width: em(700)) {
        width: rem(70);
        padding-right: 3rem !important;
      }
      @media screen and (max-width: em(500)) {
        width: rem(50);
        padding-right: 1.5rem !important;
      }
    }
    .image-box {
      img {
        width: 100px;
        @media screen and (max-width: em(750)) {
          // width: rem(80);
        }
      }
      @media screen and (max-width: em(750)) {
        padding-left: 5rem !important;
      }
      @media screen and (max-width: em(700)) {
        padding-left: 3rem !important;
      }
      @media screen and (max-width: em(500)) {
        padding-left: 1.5rem !important;
      }
    }
    @media screen and (max-width: em(750)) {
      .sec-center{
        display: none;
      }
      .show + .sec-center{
        display: block;
        margin-bottom: 20px;
      }
    }
    .navigations {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 600;
      &.show {
        display: flex;
        @media screen and (max-width: em(750)) {
          padding-top: 15px;
        }
      }
      .navigation-list {
        display: flex;
        align-items: center;
        gap: 2rem;
        .navigation-list-items {
          white-space: nowrap;
          font-weight: 200;
        }
        @media screen and (max-width: em(750)) {
          flex-direction: column;
          gap: 1rem;
        }
      }
      @media screen and (max-width: em(1000)) {
        font-size: 0.8rem;
        justify-content: center;
      }
      @media screen and (max-width: em(750)) {
        display: none;
      }
    }

    .cannect-wallet-btn {
      white-space: nowrap;
      background-color: white;
      color: black;
      font-weight: 600;
      padding-inline: 2rem;
      padding-block: 0.45rem;
      border-radius: 0.5rem;
      @media screen and (max-width: em(750)) {
        display: none;
      }
      &.nav-btn {
        font-size: 16px;
      }
    }
    .connect-toggle {
      white-space: nowrap;
      width: 14ch;
      color: black;
      font-weight: 600;
      padding-inline: 1rem;
      padding-block: 0.45rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      @media screen and (max-width: em(750)) {
        margin-bottom: 15px;
      }
    }

    .connected-chain {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      background-color: white;
      color: black;
      padding-block: rem(5);
      padding-inline: rem(13.5);
      border-radius: 0.35rem;
      min-width: 16ch;

      .label {
        font-size: rem(9);
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 0.04em;
        color: hsl(0, 4%, 4%);
      }

      .chain-name {
        font-size: rem(14);
        font-weight: 600;
        text-transform: capitalize;
      }
    }
  }
  @media screen and (max-width: em(1100)) {
    padding-inline: 5rem !important;
  }
  @media screen and (max-width: em(750)) {
    padding-inline: 0rem !important;
  }
}
