@use "./function" as *;

.loader {
    aspect-ratio: 1;
    width: rem(60);
    position: absolute;
    animation: loading 1s linear infinite;

    div {
        width: 100%;
        height: 100%;
        position: absolute;
        border: 4px solid transparent;
        border-top-color: white;
        border-radius: 50%;

        &:nth-child(2) {
            border: 4px solid transparent;
            border-bottom-color: white;
        }
    }
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
