@use "./function" as *;

.deploy-contract {
    background-image: none;
    background: #030303;
    backdrop-filter: blur(10px);

    .main-content {
        width: 100%;
        min-height: 100vh;
        color: white;
        padding-block-start: 6rem;
        padding-block-end: 3rem;
        padding-inline: 10rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 3rem;
        @media screen and (max-width: em(1100)) {
            padding-inline: 5rem;
        }
        @media screen and (max-width: em(500)) {
            padding-inline: 3rem;
        }
        @media screen and (min-width: 1600px) {
            padding-inline: 13rem;
        }
        .heading-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 0.5rem;
            padding-block: 2rem;

            .page-heading {
                font-size: 3rem;
                font-weight: 700;

                @media screen and (max-width: em(800)) {
                    font-size: 2rem;
                    font-weight: 500;
                }

                @media screen and (max-width: em(450)) {
                    font-size: 1.6rem;
                    font-weight: 500;
                }
                @media screen and (min-width: 1600px){
                    font-size: 45px;
                }
            }

            .page-info {
                font-size: 1rem;
                max-width: 35ch;
                font-weight: 300;
                @media screen and (max-width: em(800)) {
                    font-size: 1rem;
                }
            }
        }

        .contracts-section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 2rem;
            flex: 1;

            > * {
                width: 100%;
            }

            .no-data-msg-container {
                border: 1px solid rgba(255, 255, 255, 0.15);
                border-radius: 10px;
                width: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: rem(5);
                padding-inline: 1rem;
                padding-block: 1.5rem;

                .msg-heading {
                    font-size: rem(22.5);
                    font-weight: 500;
                    text-align: center;

                    @media screen and (max-width: em(750)) {
                        font-size: rem(20);
                    }

                    @media screen and (max-width: em(540)) {
                        font-size: rem(18);
                    }

                    @media screen and (max-width: em(450)) {
                        font-size: rem(16);
                    }
                }

                .msg-subheading {
                    font-size: rem(15);
                }

                .deploy-contract-btn {
                    padding-inline: em(30);
                    padding-block: em(10);
                    font-size: rem(15);
                    font-weight: 600;
                    border-radius: 5px;
                    margin-top: rem(35);

                    @media screen and (max-width: em(450)) {
                        padding-inline: em(20);
                    }
                }
            }

            .loader-position {
                display: flex;
                width: 100%;
                justify-content: center;
                flex: 1;
            }

            .contracts-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 2rem;

                @media screen and (max-width: em(750)) {
                    flex-direction: column;
                }

                @media screen and (max-width: em(600)) {
                    flex-direction: column;
                    gap: rem(10);
                }
                .heading-text {
                    font-size: 2rem;
                    font-weight: 600;
                    @media screen and (max-width: em(800)) {
                        font-size: 1.8rem;
                        font-weight: 400;
                    }
                }

                .heading-actions-container {
                    display: flex;
                    gap: 2rem;
                    align-items: center;

                    @media screen and (max-width: em(800)) {
                        gap: 1.5rem;
                    }

                    @media screen and (max-width: em(540)) {
                        margin-top: rem(20);
                        flex-direction: column;
                    }

                    .filter-chain-optionGroup {
                        display: flex;
                        flex-direction: column;
                        gap: rem(2);
                        margin-inline-start: auto;
                        position: relative;

                        .filter-chain-label {
                            font-size: rem(11.5);
                            position: absolute;
                            background-color: #030303;
                            width: max-content;
                            margin-left: rem(8);
                            padding-inline: 4px;
                            transform: translateY(-50%);
                            letter-spacing: 0.04em;
                        }

                        .filter-chain-name {
                            background-color: transparent;
                            padding-inline: rem(8);
                            padding-block-start: 0.6em;
                            padding-block-end: 0.4em;
                            border: 1px solid
                                rgba($color: #ffffff, $alpha: 0.25);
                            border-radius: 4px;
                            color: white;
                            transition: border-color 250ms linear;
                            font-size: rem(13.5);

                            &:hover {
                                border-color: rgba(
                                    $color: #ffffff,
                                    $alpha: 0.5
                                );
                                transition: border-color 250ms linear;
                            }

                            &:focus {
                                border-color: rgba(
                                    $color: #ffffff,
                                    $alpha: 0.5
                                );
                                transition: border-color 250ms linear;
                            }

                            option {
                                background: #030303;
                            }
                        }
                    }

                    .new-contract-btn {
                        display: grid;
                        place-content: center;
                        padding-block: 0.4em;
                        padding-inline: 1em;
                        background-color: white;
                        color: black;
                        font-weight: 600;
                        border-radius: 5px;
                        font-size: rem(15);
                    }
                }
            }

            .contracts-conatiner {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;

                @media screen and (max-width: em(1000)) {
                    display: flex;
                    flex-direction: column;
                }
                .contract-card {
                    grid-column: span 1;
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    align-items: center;
                    border-radius: 15px;
                    padding-block: 1rem;
                    background: linear-gradient(
                        150.94deg,
                        #000000 3.32%,
                        rgba(0, 0, 0, 0) 98.95%
                    );
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    backdrop-filter: blur(8px);
                    position: relative;
                    isolation: isolate;

                    &::after,
                    &::before {
                        content: "";
                        position: absolute;
                        display: block;
                        border-radius: 15px;
                    }

                    &::before {
                        inset: 0;
                        z-index: -1;
                        background: #030303;
                        backdrop-filter: blur(10px);
                    }

                    &::after {
                        inset: -1px;
                        z-index: -2;
                        background: linear-gradient(
                            150.94deg,
                            #ffffff 10%,
                            rgba(255, 255, 255, 0.25) 40%
                        );
                    }

                    .contract-info {
                        grid-column: span 1;

                        &.contract-name,
                        &.chain {
                            padding-block-end: 1rem;
                        }
                        &.type,
                        &.symbol {
                            padding-block-start: 1rem;

                            .name {
                                font-size: rem(18);
                            }
                        }

                        .label {
                            font-size: rem(12);
                            color: #909090;
                            font-weight: 400;
                            text-transform: uppercase;
                        }

                        .name {
                            font-weight: 300;
                        }
                    }

                    .contract-name,
                    .type {
                        padding-inline-start: 1.5rem;
                    }
                    .chain,
                    .symbol {
                        padding-inline-end: 1.5rem;
                    }

                    .contract-name {
                        font-weight: 400;
                        font-size: 1.5rem;
                    }

                    .hr {
                        grid-column: span 2;
                        width: 100%;
                        display: block;
                        border-top: 1px solid rgba(255, 255, 255, 0.35);
                    }
                }
            }
        }
    }

    .contracts-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        .contracts-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .heading-text {
                font-size: 2rem;
                font-weight: 600;
            }

            .new-contract-btn {
                display: grid;
                place-content: center;
                padding-block: 0.35em;
                padding-inline: 0.7em;
                background-color: white;
                color: black;
                font-weight: 600;
                border-radius: 5px;
                font-size: 16px;
            }
        }

        .contracts-conatiner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;

            .contract-card {
                grid-column: span 1;
                display: grid;
                grid-template-columns: 2fr 1fr;
                align-items: center;
                border-radius: 15px;
                padding-block: 1.5rem;
                background: linear-gradient(
                    150.94deg,
                    #000000 3.32%,
                    rgba(0, 0, 0, 0) 98.95%
                );
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                backdrop-filter: blur(8px);
                position: relative;
                isolation: isolate;

                &::after,
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    border-radius: 15px;
                }

                &::before {
                    inset: 0;
                    z-index: -1;
                    background: #030303;
                    backdrop-filter: blur(10px);
                }

                &::after {
                    inset: -1px;
                    z-index: -2;
                    background: linear-gradient(
                        150.94deg,
                        #ffffff 20%,
                        rgba(255, 255, 255, 0.35) 100%
                    );
                }

                .contract-info {
                    grid-column: span 1;

                    &.contract-name,
                    &.chain {
                        padding-block-end: 1rem;
                    }
                    &.type,
                    &.symbol {
                        padding-block-start: 1rem;

                        .name {
                            font-size: rem(18);
                        }
                    }

                    .label {
                        font-size: rem(12);
                        color: #909090;
                        font-weight: 400;
                        text-transform: uppercase;
                    }

                    .name {
                        font-weight: 300;
                    }
                }

                .contract-name,
                .type {
                    padding-inline-start: 1.5rem;
                }
                .chain,
                .symbol {
                    padding-inline-end: 1.5rem;
                }

                .contract-name {
                    font-weight: 400;
                    font-size: 1.5rem;
                }

                .hr {
                    grid-column: span 2;
                    width: 100%;
                    display: block;
                    border-top: 1px solid rgba(255, 255, 255, 0.35);
                }
            }
        }
    }
}
