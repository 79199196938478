@use "./function" as *;

.csv-upload-container {
  background-color: #030303;
  border: 1px solid rgba(255, 255, 255, 0.35);
  width: 100%;
  height: rem(260);
  border-radius: 0.625rem;
  position: relative;
  overflow: hidden;

  .tabs-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    display: flex;
    justify-content: space-between;

    .tab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 1rem;
      position: relative;
      height: rem(40);

      &:has(input:checked) {
        background: #2e2d2e;
      }

      .tab-label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        cursor: pointer;
      }

      .tab-control {
        position: absolute;
        display: none;
        z-index: -1;
      }
    }
  }

  .csv-uploader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: rem(220);
    padding: 2rem;
    border-radius: 0.625rem;
    position: relative;

    .add-file-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #8d8d8d;

      .add-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        font-weight: lighter;
        cursor: pointer;
      }

      .add-text {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #ffffff;
      }

      .add-message {
        font-size: 0.75rem;
        text-align: center;
      }
    }

    .file-preview-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .file-icon {
        font-size: 4rem;
      }

      .remove-file-btn {
        position: absolute;
        z-index: 1;
        top: 0.5rem;
        right: 0.5rem;
        font-size: rem(24);
        color: #8d8d8d;
      }

      .file-name {
        font-size: 0.825rem;
        text-align: center;
        color: #8d8d8d;
        margin-top: 0.5rem;
      }

      .upload-file-btn {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: 0.4rem 1rem;
        font-weight: 600;
        font-size: 0.875rem;
        border-radius: 2px;
      }
    }
  }

  .tracking-section {
    height: rem(220);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }

    .border-bottom {
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.35);
    }

    .no-tracking-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .no-tracking-icon {
        font-size: 3.5rem;
        margin-bottom: 0.5rem;
        position: relative;
      }

      .no-tracking-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #ffffff;
      }

      .no-tracking-msg {
        font-size: 0.75rem;
        text-align: center;
        color: #8d8d8d;
      }
    }

    .tracking-row-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .tracking-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 0.25rem;
        padding: 0.5rem 1rem;

        .tracking-row-upper,
        .tracking-row-lower {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
        }

        .file-name {
          font-size: 0.85rem;
          font-weight: 300;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .upload-status {
          font-size: 0.7rem;
          font-weight: 600;
          color: #030303;
          padding: 0.1rem 0.3rem;
          border-radius: 0.25rem;
        }

        .tracking-id,
        .last-modified {
          font-size: 0.75rem;
          color: #8d8d8d;
          font-weight: 500;
        }
      }

      .refresh-btn {
        margin: 0.5rem 0.5rem 0 0;
        height: 1.3rem;
        width: 1.3rem;
        border-radius: 0.2rem;

        .refresh-icon {
          font-size: 1rem;

          &.spin {
            animation: spin 1s linear infinite;
            transform-origin: center;
          }
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
