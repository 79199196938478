@use "./function" as *;
.nftDetails {
  .go-backBtn {
    width: fit-content;
    place-content: center;
    padding-block: 0.4em;
    padding-inline: 1em;
    background-color: white;
    color: black;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.9375rem;
  }
  min-height: 100vh;
  background: #0d0d0d;
  padding-inline: 10rem;
  padding-block: 5rem;
  .nftDetails-container {
    display: grid;
    grid-template-columns: calc(46% - 2.25rem) calc(54% - 2.25rem);
    grid-gap: 4.5rem;
    gap: 4.5rem;
    align-items: start;
    @media screen and (max-width: em(1100)) {
      display: flex;
      flex-direction: column;
    }
    .container-left {
      width: 100%;
      grid-column: span 1;
      border: 1px solid hsl(0 0% 32% / 0.35);
      border-radius: 5px;
      .image-box {
        margin: 0.09375rem;
        border-radius: 4px;
        overflow: hidden;
        background-color: #282828;
        height: max-content;
        display: grid;
        place-items: center;
        aspect-ratio: 1;
        position: relative;
      }
      .details-container {
        padding-inline: 0.625rem;
        gap: 0;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        .description-header {
          gap: 0.5rem;
          letter-spacing: 0.04em;
          padding-block: 0.9375rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
        .content {
          padding-block-end: rem(8);
          font-size: 0.875rem;
          line-height: 1.3;
          letter-spacing: 0.05em;
          &.created-name {
            font-size: 1.8rem;
          }
        }
      }
    }
    .container-right {
      grid-column: span 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 1rem;
      .logos {
        display: flex;
        padding-block: rem(20);
        align-items: center;
        gap: rem(25);
      }
      .title-header {
        margin-top: rem(10);
      }
      .title-name {
        font-size: 1.8rem;
      }
      .address-icon {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        .icon-container {
          aspect-ratio: 1;
          width: 1.875rem;
        }
      }
    }
  }
}
