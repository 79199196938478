@use "./function" as *;

.loader-position {
  display: flex;
  width: 100vw;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.claimer-page {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1.75fr 3fr;

  @media screen and (min-width: em(1160)) {
    height: 100vh;
  }

  @media screen and (max-width: em(1160)) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
  }

  .content {
    background-color: #fffdfb;
    padding-left: 3rem;
    padding-right: 2rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    color: black;
    position: relative;
    .count-container {
      border: 1px solid black;
      border-radius: 5px;
      .count-input {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-radius: 5px;
        overflow: hidden;

        > * {
          background-color: white;
          color: black;
          height: rem(32);
          text-align: center;
        }

        button {
          font-size: rem(20);
          width: rem(40);
          cursor: pointer;
        }
        button:hover {
          background-color: black;
          color: white;
        }
        input {
          font-size: rem(18);
          width: rem(100);
          border: none;
          border-inline: 1px solid black;
        }
        select {
          border-radius: 5px;
          border-inline: 1px solid black;
          background-color: #7d7d7d;
          padding-inline: rem(10);
          padding-block: rem(3.5);
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: em(1160)) {
      grid-row: -1/-2;
    }

    @media screen and (max-width: em(768)) {
      padding-left: 2rem;
    }

    .heading-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      .content-heading {
        padding-top: 3rem;
        padding-bottom: 10px;
        font-size: 2.3rem;

        @media screen and (max-width: em(768)) {
          font-size: 2rem;
          padding-top: 2.5rem;
        }

        @media screen and (max-width: em(480)) {
          font-size: 1.75rem;
        }
        @media screen and (max-width: em(320)) {
          font-size: 1.5rem;
        }
      }

      .wallet-address-display {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        div {
          p:nth-of-type(2) {
            font-size: 0.65rem;
            text-decoration: underline;
          }
        }

        .icon-container {
          aspect-ratio: 1;
          width: 1.875rem;
        }

        .address {
          font-size: 0.875rem;
          font-weight: 600;
        }

        a {
          .text {
            @media screen and (max-width: em(768)) {
              font-size: 0.95rem;
            }
          }
        }
      }
    }

    .content-description {
      color: #909090;
      font-size: 0.89rem;
      font-weight: 500;
      line-height: 1.5;

      @media screen and (max-width: em(480)) {
        font-size: 0.8rem;
      }

      p {
        margin-bottom: 1.2rem;
      }
      li {
        list-style-type: disc !important;
        margin-left: 2rem;
      }
      &.truncated {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        line-clamp: 8;
        -webkit-box-orient: vertical;
      }
    }

    .show-all-btn {
      font-weight: 600;
      font-size: rem(15);
      background-color: transparent;
      text-transform: uppercase;

      @media screen and (max-width: em(480)) {
        font-size: rem(13);
      }
    }

    .claimer-description-container {
      display: flex;
      flex-direction: column;
      gap: 0.9rem;
      width: 100%;

      .claimer-heading {
        font-size: 1.063rem;
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid hsl(0, 0%, 0%, 0.25);
        padding-bottom: 0.5rem;

        @media screen and (max-width: em(768)) {
          font-size: 1rem;
        }

        @media screen and (max-width: em(480)) {
          font-size: 0.975rem;
        }
      }

      .claimer-description {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid hsl(0, 0%, 0%, 0.25);
        padding-bottom: 1.5rem;

        .claimer-description-group {
          display: flex;
          flex-direction: column;
          gap: 0.35rem;
          align-items: flex-start;
          text-transform: uppercase;

          .label {
            color: #3e3e3e;
            font-size: 0.688rem;
          }

          .value {
            font-weight: 600;
            font-size: 0.875rem;

            @media screen and (max-width: em(480)) {
              font-size: rem(14);
            }
          }
        }
      }
    }

    .email-input-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 100%;

      @media screen and (max-width: em(480)) {
        flex-direction: column;
        align-items: stretch;
      }

      .email-input {
        border: 1px solid rgba(0, 0, 0, 0.6);
        padding: 0.5rem;
        border-radius: 4px;
        flex: 1;
        font-size: 0.9rem;
        font-weight: 500;

        &:focus {
          outline: none;
        }

        @media screen and (max-width: em(480)) {
          font-weight: 400;
        }
      }

      .verify-email-btn {
        display: grid;
        place-items: center;
        background-color: transparent;
        font-size: 1.063rem;
        font-weight: 600;

        @media screen and (max-width: em(768)) {
          font-size: 1rem;
        }

        @media screen and (max-width: em(480)) {
          font-size: 0.9rem;
          place-items: start;
        }

        span {
          font-size: 0.75rem;
          font-weight: 500;
          color: #555555;
        }
      }
    }

    .claim-nft-btn {
      display: grid;
      place-content: center;
      padding-inline: 2rem;
      font-size: 0.8rem;
      padding-block: 0.6rem;
      background-color: #000000;
      color: #ffffff;
      font-weight: 600;
      border-radius: 8px;
      margin-top: 1rem;
      margin-bottom: rem(300);
      width: rem(180);
      height: rem(35);

      @media screen and (max-width: em(1160)) {
        margin-bottom: 8rem;
      }

      .state-symbol {
        display: grid;
        place-items: center;
        position: relative;
        aspect-ratio: 1;
        border: 50%;

        &::before,
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
        }

        &::before {
          inset: 2px;
          background-color: #7d7d7d;
        }

        &::after {
          inset: 4px;
          background-color: #0d0d0d;
        }

        .spinner {
          font-size: 1.3rem;
          transform-origin: center;
          animation: spinning 0.75s linear infinite;
          z-index: 3;
          aspect-ratio: 1;
        }
      }
    }
  }

  .display {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: em(1160)) {
      height: auto;
    }

    .cannect-wallet-btn {
      position: absolute;
      background-color: white;
      color: black;
      font-weight: 600;
      padding-inline: 2rem;
      padding-block: 0.5rem;
      border-radius: 5rem;
      font-size: 1.063rem;
      right: 5rem;
      top: 2rem;

      @media screen and (max-width: em(1160)) {
        right: 3rem;
      }

      @media screen and (max-width: em(768)) {
        right: 2rem;
        font-size: 1rem;
      }

      @media screen and (max-width: em(480)) {
        top: 1rem;
        right: 1rem;
        font-size: 0.875rem;
      }
    }

    .display-image-container {
      place-content: center;
      width: 100%;

      img {
        height: 100vh;
        width: 100%;
        object-fit: contain;

        @media screen and (max-width: em(1160)) {
          height: auto;
        }
      }
    }
  }
}

.modal-body {
  position: absolute;
  inset: 0;
  max-height: 100vh;
  display: grid;
  place-content: center;

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4.5rem;
    aspect-ratio: 41/25;
    border-radius: 15px;
    position: relative;
    isolation: isolate;
    animation: popup 250ms linear;

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: block;
      border-radius: 15px;
    }

    &::before {
      inset: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.85);
      backdrop-filter: blur(15px);
    }

    &::after {
      inset: -1px;
      z-index: -2;
      background: linear-gradient(
        150.94deg,
        rgba(255, 255, 255, 0.25) 100%,
        #ffffff 100%
      );
    }

    .close {
      background-color: transparent;
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      display: grid;
      place-items: center;

      svg {
        width: rem(15);
        @media screen and (max-width: em(500)) {
          width: rem(10);
        }
      }
    }
    .content-container {
      display: grid;
      place-content: center;
      text-align: center;
      width: max-content;

      .heading {
        font-size: rem(24);
        font-weight: 500;
      }

      .congratulations-msg {
        font-size: rem(16);
        line-height: 1.3;
        margin-top: rem(12);
        font-weight: 300;
        color: #9e9e9e;
        max-width: 45ch;
      }

      .tweet-btn {
        background-color: white;
        color: black;
        font-size: rem(15);
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: em(12) em(36);
        border-radius: 5px;
        gap: rem(10);

        svg {
          width: rem(17);
        }
      }

      .view-nft-link {
        margin-top: rem(8);
        font-size: rem(15);
        border-bottom: 1px solid white;
        padding-inline: 1px;
        width: max-content;
        margin-inline: auto;
      }
    }
  }

  .modal1on1 {
    z-index: 9999;
    padding: 2rem;
    width: rem(550);
    @media screen and (max-width: em(750)) {
      width: rem(400);
    }
    @media screen and (max-width: em(500)) {
      width: rem(250);
      padding: 1rem;
    }
    .modal-content {
      p:nth-of-type(1) {
        font-size: rem(22);
        font-weight: 600;
        text-align: center;
        @media screen and (max-width: em(500)) {
          font-size: rem(15);
        }
      }
      p:nth-of-type(2) {
        font-size: rem(15);
        @media screen and (max-width: em(500)) {
          font-size: rem(10);
          text-align: center;
        }
      }
      @media screen and (max-width: em(500)) {
        margin-block-start: rem(10);
      }
      .modal-count {
        display: flex;
        flex-direction: row;
        gap: rem(20);
        align-items: center;
        margin-top: rem(40);
        margin-bottom: rem(20);
        @media screen and (max-width: em(500)) {
          flex-direction: column;
        }
        p {
          font-size: rem(18);
          font-weight: 600;
          @media screen and (max-width: em(500)) {
            font-size: rem(12);
          }
        }
        .modal-count-input {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          border-radius: 5px;
          overflow: hidden;

          > * {
            background-color: white;
            color: black;
            height: rem(32);
            text-align: center;
          }

          button {
            font-size: rem(20);
            width: rem(30);
            cursor: pointer;
            @media screen and (max-width: em(500)) {
              font-size: rem(15);
            }
          }
          input {
            font-size: rem(18);
            width: rem(50);
            border: none;
            border-inline: 1px solid black;
            @media screen and (max-width: em(500)) {
              font-size: rem(12);
              width: rem(30);
            }
          }
          select {
            border-radius: 5px;
            border-inline: 1px solid black;
            background-color: white;
            padding-inline: rem(10);
            padding-block: rem(3.5);
            cursor: pointer;
          }
        }
      }
    }
    .deploy-btn {
      margin-block-start: 2.5rem;
      background-color: white;
      padding: 0.8rem 1rem;
      width: 100%;
      font-weight: 600;
      font-size: rem(15);
      border-radius: 2px;
      &.mint-btn {
        @media screen and (max-width: em(500)) {
          padding: 0.5rem 0.8rem;
          width: 80%;
          font-size: rem(12);
        }
      }
    }
  }
  @keyframes popup {
    from {
      transform: scale(0.6);
    }
    60% {
      transform: scale(1.15);
    }
    to {
      transform: scale(1);
    }
  }
}
