@use "./function" as *;

.new-contract {
  background-image: none;
  background: #0d0d0d;
  width: 100%;
  overflow-x: hidden;
  backdrop-filter: blur(10px);
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding-inline: 10rem;
  padding-top: 3rem;

  @media screen and (min-width: 1600px) {
    padding-inline: 13rem;
  }

  &:has(.biconomy-type-container) {
    position: relative;
    height: 100%;
  }

  @media screen and (max-width: em(1100)) {
    padding-inline: 5rem;
  }
  @media screen and (max-width: em(700)) {
    padding-inline: 3rem;
  }
  @media screen and (max-width: em(500)) {
    padding-inline: 1.5rem;
  }
  &.mintMain {
    padding-block: 0;
    height: 100vh;
  }
  .main-content {
    width: 100%;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: start;
    padding-top: 3rem;
    padding-inline: 10rem;
    // position: relative;
    height: 100%;

    @media screen and (min-width: 1600px) {
      padding-inline: 13rem;
    }
    @media screen and (max-width: em(1200)) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: em(1100)) {
      padding-inline: 5rem;
    }
    @media screen and (max-width: em(700)) {
      padding-inline: 3rem;
    }
    @media screen and (max-width: em(500)) {
      padding-inline: 1.5rem;
    }
    .main-scroll {
      width: 100%;
      &:has(.biconomy-type-container) {
        z-index: 1;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column: 1/3;
        top: 3rem;
        bottom: 4rem;
        @media screen and (max-width: em(1250)) {
          position: relative;
          grid-template-columns: 1fr;
        }
      }
      &.mint-scroll {
        z-index: 1;
        top: 3rem;
        bottom: 4rem;
        margin-block: 3rem;
        grid-template-columns: 1fr 1fr;
        display: grid;
        grid-column: 1/3;
        position: absolute;
        @media screen and (max-width: em(1250)) {
          position: relative;
          grid-template-columns: 1fr;
        }
      }
      height: calc(100vh - 6rem);
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: 0;
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (max-width: em(1250)) {
        height: fit-content;
      }
    }

    &:has(.biconomy-type-container) {
      align-items: flex-start;
    }

    .content {
      position: sticky;
      top: 6.25rem;
      padding-block: 2rem;
      grid-column: span 1;
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      align-items: flex-start;
      justify-content: center;
      @media screen and (min-width: 1600px) {
        padding-top: 0;
      }

      @media screen and (max-width: em(1200)) {
        position: unset;
        top: unset;
        width: 100%;
      }

      .mintBtns-logo {
        display: flex;
        align-items: center;
        gap: rem(25);
        z-index: 9999;
      }

      &.mintTop {
        margin-top: rem(52);
        .mintLeft-btns {
          display: flex;
          gap: rem(20);
          .btn-1 {
            width: 11ch;
          }
          .btn-2 {
            width: 16ch;
          }
        }

        .mintOne {
          width: max-content;
        }

        .minted-nft-btns-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          width: 100%;
          max-width: 35ch;
          margin-block-start: 2.5rem;

          .minted-nfts-btns {
            margin-block-start: 0 !important;
            margin: 0 !important;
          }

          .twitter-share-button {
            button {
              margin-block-start: 0 !important;
            }
          }
        }

        .divider {
          width: 100%;
          max-width: 44ch;
          border-top: 1px solid rgb(255, 255, 255, 0.15);
          border-radius: 999px;
        }
      }
      .mTop {
        margin-top: rem(60);
      }

      .buttonsLoad {
        margin-block-start: 2.5rem;
        display: flex;
        flex-direction: column;
        place-items: center;
      }
      .loader-container {
        justify-content: center;
        width: 50px;
        display: flex;
        height: 44.59px;
        align-items: center;
        .loader {
          font-size: 1.3rem;
          transform-origin: center;
          animation: spinning 0.75s linear infinite;
          z-index: 3;
          aspect-ratio: 1;
        }
      }

      .content-heading {
        font-size: 2rem;
        font-weight: 600;
        @media screen and (min-width: 1400px) {
          width: 20ch;
        }
      }

      .content-description {
        line-height: 1.35;
        font-size: rem(16);
        font-weight: 300;
        max-width: 44ch;
        @media screen and (min-width: 1600px) {
          font-size: 16px;
        }
      }

      .fill-gas-wrapper {
        width: min(100%, 44ch);
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: -1rem;

        .fill-gas {
          width: calc(100% - rem(5) - 1rem);
          text-align: center;
          font-size: rem(14);
          letter-spacing: 0.02em;
          font-weight: 700;
          background: white;
          padding: 0.7em 1.8em;
          border-radius: 2px;
          color: black;
        }
      }

      .deployment-processing-states {
        width: 100%;
        max-width: 44ch;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.75rem;

        .states-description {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #7d7d7d;

          &:has(.check-mark),
          &:has(.spinner) {
            color: white;
          }

          .state-text {
            font-size: 0.95rem;
          }

          .state-symbol {
            display: grid;
            place-items: center;

            &:has(.spinner) {
              position: relative;
              aspect-ratio: 1;
              border: 50%;

              &::before,
              &::after {
                position: absolute;
                content: "";
                display: block;
                border-radius: 50%;
              }

              &::before {
                inset: 2px;
                background-color: #7d7d7d;
              }

              &::after {
                inset: 4px;
                background-color: #0d0d0d;
              }
            }

            .check-mark {
              color: #61d345;
            }

            .spinner {
              font-size: 1.1rem;
              transform-origin: center;
              animation: spinning 0.75s linear infinite;
              z-index: 3;
              aspect-ratio: 1;
            }
          }
        }
      }

      .deployed-address-container {
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.6rem;
        font-size: 0.95rem;
        margin-top: 0.8rem;

        .contract-address-display {
          border: 1px solid rgba(255, 255, 255, 0.25);
          padding-block: 0.5em;
          padding-inline: rem(16);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          .contract-address {
            background-color: transparent;
            color: rgba(255, 255, 255, 0.8);
            font-weight: 300;
          }
        }
      }

      .deploy-btn {
        width: 100%;
        margin-block-start: 2.5rem;
        background-color: white;
        padding: 0.75em 1em;
        font-weight: 700;
        font-size: 16px;
        border-radius: 2px;
        position: relative;
        z-index: 9999;

        @media screen and (max-width: em(450)) {
          margin-block-start: 1rem;
        }
        @media screen and (min-width: 1600px) {
          font-size: 16px;
        }
        &.goBack {
          margin-block-start: 0rem;
        }
      }
    }

    .contract-information {
      margin-top: 3.25rem;
      align-self: start;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.35);
      &.mBottom {
        margin-bottom: 3rem;
      }
      @media screen and (max-width: em(1250)) {
        margin-bottom: rem(100);
      }
      &:has(.biconomy-type-container) {
        margin-left: auto;
        grid-column: -1/-2;
      }

      &.mintForm {
        grid-column: -1/-2;
        margin-bottom: 3rem;
        @media screen and (max-width: em(1250)) {
          grid-column: 0;
        }
      }
      .desc {
        textarea {
          height: 115px;
        }
      }
      .information-group {
        background: #030303;
        width: 100%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        backdrop-filter: blur(8px);
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        border-top: 0.5px solid rgba(255, 255, 255, 0.35);

        @media screen and (max-width: em(500)) {
          padding: 1rem;
        }
        label {
          text-transform: capitalize;
        }
        &:has(input:required, textarea:required) {
          .label {
            &::after {
              margin-left: 3px;
              content: " *";
              color: hsl(0, 100%, 50%, 0.5);
              display: inline-block;
            }
          }
        }
        &.video-box {
          display: flex;
          flex-direction: row;
          padding: 0rem;
          p {
            cursor: pointer;
            border-right: 1px solid rgba(255, 255, 255, 0.35);
            padding: 1rem;
            text-transform: uppercase;
            color: #8d8d8d;
            flex-basis: 50%;
            text-align: center;
          }
        }
        &.nftContent {
          padding: 0;
          overflow: hidden;
        }
        .copyAddress {
          display: flex;
          justify-content: space-between;
        }
        &:nth-of-type(1) {
          border-top: 0;
          border-radius: 15px 15px 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 15px 15px;
        }
        .properties {
          display: flex;
          flex-direction: row;
          gap: rem(0);
          align-items: stretch;
          @media screen and (max-width: em(600)) {
            flex-direction: column;
          }
          > * {
            background-color: transparent;
          }
          button {
            color: white;
            height: 100%;
            font-size: rem(20);
            padding: rem(6);
            border: 1px solid rgba(255, 255, 255, 0.35);
            border-right: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            aspect-ratio: 1;
            @media screen and (max-width: em(600)) {
              width: 8%;
              border-right: 1px solid rgba(255, 255, 255, 0.35);
              border-radius: 4px;
              padding: 0;
            }
          }
          .properties-form-control {
            flex: 1;
            padding: 0.5rem;
            border: 1px solid rgba(255, 255, 255, 0.35);
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            color: white;
            &:focus {
              outline: none;
            }
            &:nth-of-type(2) {
              margin-left: rem(3);
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
              @media screen and (max-width: em(600)) {
                margin-left: 0px;
              }
            }
            @media screen and (max-width: em(600)) {
              border-radius: 4px;
            }
          }
        }
        .image-cont {
          background-color: #2e2d2e;
          width: 100%;
          // aspect-ratio: 1;
          padding-block: rem(90);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: rem(320);
        }

        .information-group-img {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          background: hsl(300, 1%, 8%);

          img,
          iframe {
            aspect-ratio: 1;
            width: 100%;
            height: 100%;
            max-height: rem(320);
            object-fit: contain;
            z-index: 0;
          }

          .remove-img-btn {
            position: absolute;
            z-index: 1;
            top: 0.5rem;
            right: 0.5rem;
            font-size: rem(28);
            color: #8d8d8d;
          }
        }

        .add-properties {
          width: fit-content;
          margin-block-start: 2.5rem;
          background-color: white;
          padding: 0.3rem 0.8rem;
          font-weight: 600;
          font-size: rem(15);
          border-radius: 2px;
        }

        .add {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: rem(60);
          font-weight: lighter;
          cursor: pointer;
          color: #8d8d8d;
          div {
            display: flex;
            justify-content: center;
          }
          p {
            font-size: rem(15);
            font-weight: 400;
          }
        }
        .add_upload {
          font-size: rem(12);
          color: #8d8d8d;
        }
        .label {
          font-size: rem(12.5);
          color: #8d8d8d;
          font-weight: 400;
        }

        .name {
          font-size: 1.0625rem;
          font-weight: 300;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          border: 0;
          color: white;
          padding: 0.2em 0 0 0;
          @media screen and (max-width: em(350)) {
            font-size: 1rem;
          }

          &::placeholder {
            font-size: 1.0625rem;
            font-weight: 500;
            @media screen and (max-width: em(500)) {
              font-size: 0.8rem;
            }
          }

          &:focus {
            outline: none;
          }

          svg {
            width: 0.5rem;
            fill: hsl(0, 0%, 80%, 0.35);

            &:hover {
              fill: white;
              transition: fill 100ms linear;
            }
          }
        }

        select.name {
          background: #030303;
          backdrop-filter: blur(8px);
          display: flex;

          @media screen and (max-width: em(450)) {
            font-size: 0.8rem;
          }
          option {
            font-size: 1rem;
          }
        }

        .biconomy-type-container {
          margin-top: 1rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          border: 1px solid rgba(255, 255, 255, 0.35);

          .information-group {
            padding: 0.8rem;
            &.whitelist-box {
              display: flex;
              flex-direction: row;
            }
            .add-whitelist {
              display: flex;
              gap: rem(10);
            }
            .name {
              font-size: 1.15rem;
              @media screen and (max-width: em(450)) {
                font-size: 0.8rem;
              }
            }
            &.token {
              .name {
                font-size: 0.8rem !important;
                &::placeholder {
                  font-size: 0.8rem;
                }
              }
            }

            small {
              color: #f83a3a;
              font-size: 0.8rem;
              margin-top: 0.2rem;
            }
          }
        }
      }
    }
  }
}
.marginT {
  margin-top: rem(50);
  @media screen and (max-width: em(750)) {
    margin-top: 0;
  }
}
.contract-details {
  padding-block: 2rem;

  .main-content {
    .content {
      .content-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        @media screen and (max-width: em(450)) {
          flex-direction: column;
          .deploy-btn {
            width: 20ch;
          }
        }
      }
      .details {
        position: relative;
        width: 85%;
        min-height: rem(100);
        background: #030303;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.35);
        padding-inline: 1.5rem;
        padding-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: rem(150);
        overflow-y: scroll;

        @media screen and (max-width: em(1200)) {
          width: 100%;
        }

        .whiteList {
          display: flex;
          .whiteList-status {
            justify-content: flex-end;
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &_head {
          left: 0;
          top: 0;
          z-index: 2;
          gap: rem(8);
          padding-top: 1.5rem;
          padding-bottom: 0.3rem;
          background: #030303;
          display: flex;
          flex-direction: column;
          position: sticky;
        }
        li {
          list-style-type: disc;
          display: flex;
          align-items: center;
          position: relative;
          .whitelist-userAdd {
            max-width: 25ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &::before {
            content: "";
            aspect-ratio: 1;
            width: rem(5);
            border-radius: 50%;
            background-color: white;
            display: block;
            margin-right: 6px;
          }

          .check {
            color: #ffffff;
            margin-left: 6px;
            justify-self: flex-end;
            margin-left: auto;
            white-space: nowrap;
          }
        }
      }
      padding-block-start: 3.5rem;

      .whitelis-main-container {
        width: 85%;

        @media screen and (max-width: em(1200)) {
          width: 100%;
        }
      }

      .whitelist-input-divider {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        &::before,
        &::after {
          content: "";
          display: block;
          flex: 1;
          height: 1px;
          width: 100%;
          background-color: #8d8d8d;
        }
      }

      .whitelist-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        @media screen and (max-width: em(450)) {
          flex-direction: column;
          align-items: initial;
        }
        .whitelist-input {
          border: 1px solid rgba(0, 0, 0, 0.6);
          padding: 0.8rem;
          border-radius: 4px;
          font-size: 0.9rem;
          font-weight: 500;
          min-width: 0;
          flex: 1;

          &:focus {
            outline: none;
          }
        }

        .add-whitelist-btn {
          display: grid;
          place-items: center;
          background-color: white;
          padding: 0.8rem 1rem;
          font-weight: 600;
          font-size: rem(15);
          border-radius: 2px;
        }
      }
      .deployed-date {
        margin-top: rem(32);
      }

      .line {
        width: 100%;
        max-width: 44ch;
        border-top: 1px solid rgba(255, 255, 255, 0.35);

        @media screen and (max-width: em(1200)) {
          max-width: 100%;
        }
      }

      .collectionBtns {
        display: flex;
        align-items: center;
        margin-top: rem(12);
        gap: rem(25);

        .collectionBtns-logo {
          display: flex;
          align-items: center;
          gap: rem(25);
        }
      }
    }

    .contract-information {
      align-self: start;

      .information-group {
        .contract-address {
          font-size: 1rem;
          @media screen and (max-width: em(550)) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: em(450)) {
            font-size: 0.6rem;
          }
        }

        .abi-string {
          font-size: rem(15);
          font-weight: 100;
          background-color: transparent;
          color: white;
          border: none;
          resize: none;
          font-family: monospace;
          line-height: 1.5;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &:focus {
            outline: none;
          }
        }

        &.abi-string-group {
          .abi-label-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .information-group-container {
        display: flex;
        border-top: 0.5px solid rgba(255, 255, 255, 0.35);
        overflow: hidden;
        border-radius: 0 0 15px 15px;

        .information-group {
          border-top: 0;
          border-radius: 0;

          &:first-of-type {
            border-right: 0.5px solid rgba(255, 255, 255, 0.35);
          }
        }
      }
    }
  }
}

.copyBtn {
  font-size: rem(16);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: rem(5);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.35);
  position: relative;
  background-color: transparent;
  display: grid;
  place-items: center;
  width: max-content;

  &::after {
    position: absolute;
    display: block;
    background-color: #0d0d0d;
    top: 0;
    right: 0;
    transform: scale(0) translateY(calc(-100% - 0.3rem)) translateX(calc(50% - rem(15)));
    color: rgb(255, 255, 255, 0.9);
    padding: em(7.5) em(15);
    outline: rem(0.5) solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    font-weight: 300;
    font-size: rem(12);
    letter-spacing: 0.03em;
    z-index: 1;
    width: max-content;
    opacity: 0;
  }

  &.abiCopyBtn {
    &::after {
      content: "Copy ABI to Clipboard";
    }
  }

  &.contractAddressCoptBtn {
    &::after {
      content: "Copy Contract Address to Clipboard";
    }
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.2);
    color: #8d8d8d;

    &::after {
      transform: scale(1) translateY(calc(-100% - 0.3rem)) translateX(calc(50% - rem(15)));
      opacity: 1;
    }
  }
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
